import * as React from "react"

const JunoSvgIcon = (props: any) => (
  <svg
    viewBox="0 0 220 220"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(-20 -45)">
      <ellipse
        cx={130.54}
        cy={169.52}
        rx={92.929}
        ry={90.663}
        fill="#fff"
        style={{
          paintOrder: "normal",
        }}
      />
      <ellipse
        cx={130.54}
        cy={167.5}
        rx={92.929}
        ry={90.663}
        stroke="#fff"
        strokeWidth={6.841}
        style={{
          paintOrder: "normal",
          color: "black",
        }}
      />
      <g
        clipRule="evenodd"
        fill="#fff"
        fillRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit={2}
      >
        <path
          d="M134.05 135.747c0-1.742-1.335-3.154-2.982-3.154h-.004c-1.646 0-2.981 1.412-2.981 3.154v72.826c0 1.742 1.335 3.154 2.981 3.154h.004c1.647 0 2.983-1.412 2.983-3.154v-72.826z"/>
        <path
          d="M122 195.764c-1.646-.002-2.983 1.41-2.984 3.152v.003c-.002 1.74 1.333 3.154 2.98 3.155l17.882.016c1.648.002 2.984-1.41 2.986-3.15v-.003c.001-1.743-1.333-3.156-2.98-3.157L122 195.764zM107.256 154.51c-1.648-.002-2.984 1.409-2.985 3.15v.004c-.001 1.74 1.332 3.154 2.98 3.156l47.467.041c1.647.002 2.983-1.41 2.985-3.15v-.003c.001-1.742-1.334-3.156-2.98-3.157l-47.467-.042z"/>
        <path
          d="M117.092 138.566a2.87 2.87 0 0 0-4.219.005l-.002.003c-1.163 1.231-1.16 3.228.005 4.459l32.103 33.87a2.87 2.87 0 0 0 4.218-.003l.002-.003c1.164-1.233 1.162-3.23-.004-4.46l-32.103-33.871z"/>
        <path
          d="M112.916 172.49c-1.163 1.234-1.16 3.23.008 4.46l.002.003a2.87 2.87 0 0 0 4.217-.009l32.012-33.967c1.163-1.234 1.16-3.231-.006-4.46l-.003-.002a2.87 2.87 0 0 0-4.218.007l-32.012 33.968z"/>
      </g>
      <ellipse
        cx={130.57}
        cy={168.12}
        rx={81.879}
        ry={79.882}
        fill="none"
        stroke="#f0827d"
        strokeWidth={3.93}
        style={{
          paintOrder: "normal",
        }}
      />
    </g>
    <style>{".st0{fill:#043bea}"}</style>
  </svg>
)

export default JunoSvgIcon
